<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <q-b-header></q-b-header>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-row>
              <v-col sm="1" md="1"></v-col>
              <v-col sm="11" md="4" class="mt-1">
                <v-row>
                  <v-col><span style="font-size:25px">Answers:</span></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                <span v-if="performance.correct" style="color:#39bf88;font-size:25px">Correct:
                  {{ Math.floor(performance.correct * 100 / (performance.correct + performance.incorrect)) }}%
                </span>
                    <span v-else style="color:#39bf88;font-size:25px">Correct:
                  There is no correct answer.
                </span>
                  </v-col>
                  <v-col cols="6">
                <span v-if="performance.incorrect" style="color:#ff8f4e;font-size:25px">Incorrect:
                  {{ Math.floor(performance.incorrect * 100 / (performance.correct + performance.incorrect)) }}%
                </span>
                    <span v-else style="color:#ff8f4e;font-size:25px">Incorrect:
                  There is no incorrect answer.
                </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                    v-model="performance.score"
                    color="#39bf88"
                    height="50"
                    background-color="#fdbd68"
                    style="border-radius: 15px;"
                ></v-progress-linear>
                <v-row class="my-2">
                  <v-col cols="4"><span class="big">Correct:{{ performance.correct }}</span>
                  </v-col>
                  <v-col cols="4">
                <span v-if="performance.correct+performance.incorrect" class="big">
                  Total:{{ performance.correct + performance.incorrect }}
                </span>
                    <span v-else class="big">
                  Test your learning first!
                </span>
                  </v-col>
                  <v-col cols="4"><span class="big">Incorrect:{{ performance.incorrect }}</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="mt-1">
                  <v-col><span style="font-size:25px">Questions:</span></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                <span
                    v-if="performance.total"
                    style="color:#39bf88;font-size:25px">Used: {{
                    Math.floor(performance.total * 100 / (performance.total + performance.unused))
                  }}%
                </span>
                    <span
                        v-else
                        style="color:#39bf88;font-size:25px">Used: There is no used question.
                </span>
                  </v-col>
                  <v-col cols="6">
                <span
                    v-if="performance.unused"
                    style="color:#ff8f4e;font-size:25px">Unused:  {{
                    Math.floor(performance.unused * 100 / (performance.total + performance.unused))}}%
                </span>
                    <span
                        v-else
                        style="color:#ff8f4e;font-size:25px">Unused:There is no unused question.
                </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                    v-model="performance.usedRate"
                    color="#39bf88"
                    height="50"
                    background-color="#fdbd68"
                    style="border-radius: 15px;"
                ></v-progress-linear>
                <v-row class="my-2">
                  <v-col cols="4"><span class="big">Used:{{ performance.total }}</span>
                  </v-col>
                  <v-col cols="4">
                <span v-if="performance.total + performance.unused" class="big">
                  Total:{{ performance.total + performance.unused }}
                </span>
                    <span v-else class="big">
                                    Test your learning first!
                </span>
                  </v-col>
                  <v-col cols="4"><span class="big">Unused:{{ performance.unused }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="11" md="7">
                <div class="col text-center">
                  <div id="fluid-meter-2"></div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else>
      <div>
        <q-b-header-mobile></q-b-header-mobile>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mt-1">
                <v-row>
                  <v-col class="text-left"><span style="font-size:20px;color: #39bf88">Answers:</span></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                <span v-if="performance.correct" style="color:#39bf88;font-size:20px">Correct:
                  {{ Math.floor(performance.correct * 100 / (performance.correct + performance.incorrect)) }}%
                </span>
                    <span v-else style="color:#39bf88;font-size:20px">Correct:
                  There is no correct answer.
                </span>
                  </v-col>
                  <v-col cols="6">
                <span v-if="performance.incorrect" style="color:#ff8f4e;font-size:20px">Incorrect:
                  {{ Math.floor(performance.incorrect * 100 / (performance.correct + performance.incorrect)) }}%
                </span>
                    <span v-else style="color:#ff8f4e;font-size:20px">Incorrect:
                  There is no incorrect answer.
                </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                    v-model="performance.score"
                    color="#39bf88"
                    height="50"
                    background-color="#fdbd68"
                    style="border-radius: 15px;"
                ></v-progress-linear>
                <v-row class="my-2">
                  <v-col cols="4"><span class="big">Correct:{{ performance.correct }}</span>
                  </v-col>
                  <v-col cols="4">
                <span v-if="performance.correct+performance.incorrect" class="big">
                  Total:{{ performance.correct + performance.incorrect }}
                </span>
                    <span v-else class="big">
                  Test your learning first!
                </span>
                  </v-col>
                  <v-col cols="4"><span class="big">Incorrect:{{ performance.incorrect }}</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="mt-1">
                  <v-col class="text-left"><span style="font-size:20px;color: #39bf88">Questions:</span></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                <span
                    v-if="performance.total"
                    style="color:#39bf88;font-size:20px">Used: {{
                    Math.floor(performance.total * 100 / (performance.total + performance.unused))
                  }}%
                </span>
                    <span
                        v-else
                        style="color:#39bf88;font-size:20px">Used: There is no used question.
                </span>
                  </v-col>
                  <v-col cols="6">
                <span
                    v-if="performance.unused"
                    style="color:#ff8f4e;font-size:20px">Unused:  {{
                    Math.floor(performance.unused * 100 / (performance.total + performance.unused))}}%
                </span>
                    <span
                        v-else
                        style="color:#ff8f4e;font-size:20px">Unused:There is no unused question.
                </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                    v-model="performance.usedRate"
                    color="#39bf88"
                    height="50"
                    background-color="#fdbd68"
                    style="border-radius: 15px;"
                ></v-progress-linear>
                <v-row class="my-2">
                  <v-col cols="4"><span class="big">Used:{{ performance.total }}</span>
                  </v-col>
                  <v-col cols="4">
                <span v-if="performance.total + performance.unused" class="big">
                  Total:{{ performance.total + performance.unused }}
                </span>
                    <span v-else class="big">
                                    Test your learning first!
                </span>
                  </v-col>
                  <v-col cols="4"><span class="big">Unused:{{ performance.unused }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="col text-center">
                  <div id="fluid-meter-2" style="margin-top: -40px!important;margin-bottom: 80px!important;"></div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>

</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  components: {
    QBHeaderMobile,
    QBHeader,
  },
  data() {
    return {
      screenType: null,
      performance: [],
      borderColor: '',
      backColor: '',
      moj: '',
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    FluidMeter() {
      var context
      var targetContainer

      var time = null
      var dt = null

      var options = {
        drawShadow: true,
        drawText: true,
        drawPercentageSign: true,
        drawBubbles: true,
        fontSize: "70px",
        fontFamily: "Arial",
        fontFillStyle: "white",
        size: 300,
        borderWidth: 25,
        backgroundColor: "#e2e2e2",
        foregroundColor: "#fafafa"
      }

      var currentFillPercentage = 0
      var fillPercentage = 0

      //#region fluid context values
      var foregroundFluidLayer = {
        fillStyle: "purple",
        angle: 0,
        horizontalPosition: 0,
        angularSpeed: 0,
        maxAmplitude: 9,
        frequency: 30,
        horizontalSpeed: -150,
        initialHeight: 0
      }

      var backgroundFluidLayer = {
        fillStyle: "pink",
        angle: 0,
        horizontalPosition: 0,
        angularSpeed: 140,
        maxAmplitude: 12,
        frequency: 40,
        horizontalSpeed: 150,
        initialHeight: 0
      }

      var bubblesLayer = {
        bubbles: [],
        amount: 12,
        speed: 20,
        current: 0,
        swing: 0,
        size: 2,
        reset: function (bubble) {
          // calculate the area where to spawn the bubble based on the fluid area
          var meterBottom = (options.size - (options.size - getMeterRadius()) / 2) - options.borderWidth
          var fluidAmount = currentFillPercentage * (getMeterRadius() - options.borderWidth * 2) / 100

          bubble.r = random(this.size, this.size * 2) / 2
          bubble.x = random(0, options.size)
          bubble.y = random(meterBottom, meterBottom - fluidAmount)
          bubble.velX = 0
          bubble.velY = random(this.speed, this.speed * 2)
          bubble.swing = random(0, 2 * Math.PI)
        },
        init() {
          for (var i = 0; i < this.amount; i++) {

            var meterBottom = (options.size - (options.size - getMeterRadius()) / 2) - options.borderWidth
            var fluidAmount = currentFillPercentage * (getMeterRadius() - options.borderWidth * 2) / 100
            this.bubbles.push({
              x: random(0, options.size),
              y: random(meterBottom, meterBottom - fluidAmount),
              r: random(this.size, this.size * 2) / 2,
              velX: 0,
              velY: random(this.speed, this.speed * 2)
            })
          }
        }
      }

      //#endregion

      /**
       * initializes and mount the canvas element on the document
       */
      function setupCanvas() {
        var canvas = document.createElement('canvas')
        canvas.width = options.size
        canvas.height = options.size
        canvas.imageSmoothingEnabled = true
        context = canvas.getContext("2d")
        targetContainer.appendChild(canvas)

        // shadow is not required  to be on the draw loop
        //#region shadow
        if (options.drawShadow) {
          context.save()
          context.beginPath()
          context.filter = "drop-shadow(0px 4px 6px rgba(0,0,0,0.1))"
          context.arc(options.size / 2, options.size / 2, getMeterRadius() / 2, 0, 2 * Math.PI)
          context.closePath()
          context.fill()
          context.restore()
        }
        //#endregion
      }

      /**
       * draw cycle
       */
      function draw() {
        var now = new Date().getTime()
        dt = (now - (time || now)) / 1000
        time = now

        requestAnimationFrame(draw)
        context.clearRect(0, 0, options.width, options.height)
        drawMeterBackground()
        drawFluid(dt)
        if (options.drawText) {
          drawText()
        }
        drawMeterForeground()
      }

      function drawMeterBackground() {
        context.save()
        context.fillStyle = options.backgroundColor
        context.beginPath()
        context.arc(options.size / 2, options.size / 2, getMeterRadius() / 2 - options.borderWidth, 0, 2 * Math.PI)
        context.closePath()
        context.fill()
        context.restore()
      }

      function drawMeterForeground() {
        context.save()
        context.lineWidth = options.borderWidth
        context.strokeStyle = options.foregroundColor
        context.beginPath()
        context.arc(options.size / 2, options.size / 2, getMeterRadius() / 2 - options.borderWidth / 2, 0, 2 * Math.PI)
        context.closePath()
        context.stroke()
        context.restore()
      }

      /**
       * draws the fluid contents of the meter
       * @param  {} dt elapsed time since last frame
       */
      function drawFluid(dt) {
        context.save()
        context.arc(options.size / 2, options.size / 2, getMeterRadius() / 2 - options.borderWidth, 0, Math.PI * 2)
        context.clip()
        drawFluidLayer(backgroundFluidLayer, dt)
        drawFluidLayer(foregroundFluidLayer, dt)
        if (options.drawBubbles) {
          drawFluidMask(foregroundFluidLayer, dt)
          drawBubblesLayer(dt)
        }
        context.restore()
      }


      /**
       * draws the foreground fluid layer
       * @param  {} dt elapsed time since last frame
       */
      function drawFluidLayer(layer, dt) {
        // calculate wave angle
        if (layer.angularSpeed > 0) {
          layer.angle += layer.angularSpeed * dt
          layer.angle = layer.angle < 0 ? layer.angle + 360 : layer.angle
        }

        // calculate horizontal position
        layer.horizontalPosition += layer.horizontalSpeed * dt
        if (layer.horizontalSpeed > 0) {
          layer.horizontalPosition > Math.pow(2, 53) ? 0 : layer.horizontalPosition
        } else if (layer.horizontalPosition < 0) {
          layer.horizontalPosition < -1 * Math.pow(2, 53) ? 0 : layer.horizontalPosition
        }

        var x = 0
        var y = 0
        var amplitude = layer.maxAmplitude * Math.sin(layer.angle * Math.PI / 180)

        var meterBottom = (options.size - (options.size - getMeterRadius()) / 2) - options.borderWidth
        var fluidAmount = currentFillPercentage * (getMeterRadius() - options.borderWidth * 2) / 100

        if (currentFillPercentage < fillPercentage) {
          currentFillPercentage += 15 * dt
        } else if (currentFillPercentage > fillPercentage) {
          currentFillPercentage -= 15 * dt
        }

        layer.initialHeight = meterBottom - fluidAmount

        context.save()
        context.beginPath()

        context.lineTo(0, layer.initialHeight)

        while (x < options.size) {
          y = layer.initialHeight + amplitude * Math.sin((x + layer.horizontalPosition) / layer.frequency)
          context.lineTo(x, y)
          x++
        }

        context.lineTo(x, options.size)
        context.lineTo(0, options.size)
        context.closePath()

        context.fillStyle = layer.fillStyle
        context.fill()
        context.restore()
      }

      /**
       * clipping mask for objects within the fluid constrains
       * @param {Object} layer layer to be used as a mask
       */
      function drawFluidMask(layer) {
        var x = 0
        var y = 0
        var amplitude = layer.maxAmplitude * Math.sin(layer.angle * Math.PI / 180)

        context.beginPath()

        context.lineTo(0, layer.initialHeight)

        while (x < options.size) {
          y = layer.initialHeight + amplitude * Math.sin((x + layer.horizontalPosition) / layer.frequency)
          context.lineTo(x, y)
          x++
        }
        context.lineTo(x, options.size)
        context.lineTo(0, options.size)
        context.closePath()
        context.clip()
      }

      function drawBubblesLayer(dt) {
        context.save()
        for (var i = 0; i < bubblesLayer.bubbles.length; i++) {
          var bubble = bubblesLayer.bubbles[i]

          context.beginPath()
          context.strokeStyle = 'white'
          context.arc(bubble.x, bubble.y, bubble.r, 2 * Math.PI, false)
          context.stroke()
          context.closePath()

          var currentSpeed = bubblesLayer.current * dt

          bubble.velX = Math.abs(bubble.velX) < Math.abs(bubblesLayer.current) ? bubble.velX + currentSpeed : bubblesLayer.current
          bubble.y = bubble.y - bubble.velY * dt
          bubble.x = bubble.x + (bubblesLayer.swing ? 0.4 * Math.cos(bubblesLayer.swing += 0.03) * bubblesLayer.swing : 0) + bubble.velX * 0.5

          // determine if current bubble is outside the safe area
          var meterBottom = (options.size - (options.size - getMeterRadius()) / 2) - options.borderWidth
          var fluidAmount = currentFillPercentage * (getMeterRadius() - options.borderWidth * 2) / 100

          if (bubble.y <= meterBottom - fluidAmount) {
            bubblesLayer.reset(bubble)
          }

        }
        context.restore()
      }

      function drawText() {

        var text = options.drawPercentageSign ?
            currentFillPercentage.toFixed(0) + "%" : currentFillPercentage.toFixed(0)

        context.save()
        context.font = getFontSize()
        context.fillStyle = options.fontFillStyle
        context.textAlign = "center"
        context.textBaseline = 'middle'
        context.filter = "drop-shadow(0px 0px 5px rgba(0,0,0,0.4))"
        context.fillText(text, options.size / 2, options.size / 2)
        context.restore()
      }

      //#region helper methods
      function clamp(number, min, max) {
        return Math.min(Math.max(number, min), max)
      }

      function getMeterRadius() {
        return options.size * 0.9
      }

      function random(min, max) {
        var delta = max - min
        return max === min ? min : Math.random() * delta + min
      }

      function getFontSize() {
        return options.fontSize + " " + options.fontFamily
      }

      //#endregion

      return {
        init: function (env) {
          if (!env.targetContainer)
            throw "empty or invalid container"

          targetContainer = env.targetContainer
          fillPercentage = clamp(env.fillPercentage, 0, 100)

          if (env.options) {
            options.drawShadow = env.options.drawShadow === false ? false : true
            options.size = env.options.size
            options.drawBubbles = env.options.drawBubbles === false ? false : true
            options.borderWidth = env.options.borderWidth || options.borderWidth
            options.foregroundFluidColor = env.options.foregroundFluidColor || options.foregroundFluidColor
            options.backgroundFluidColor = env.options.backgroundFluidColor || options.backgroundFluidColor
            options.backgroundColor = env.options.backgroundColor || options.backgroundColor
            options.foregroundColor = env.options.foregroundColor || options.foregroundColor

            options.drawText = env.options.drawText === false ? false : true
            options.drawPercentageSign = env.options.drawPercentageSign === false ? false : true
            options.fontSize = env.options.fontSize || options.fontSize
            options.fontFamily = env.options.fontFamily || options.fontFamily
            options.fontFillStyle = env.options.fontFillStyle || options.fontFillStyle
            // fluid settings

            if (env.options.foregroundFluidLayer) {
              foregroundFluidLayer.fillStyle = env.options.foregroundFluidLayer.fillStyle || foregroundFluidLayer.fillStyle
              foregroundFluidLayer.angularSpeed = env.options.foregroundFluidLayer.angularSpeed || foregroundFluidLayer.angularSpeed
              foregroundFluidLayer.maxAmplitude = env.options.foregroundFluidLayer.maxAmplitude || foregroundFluidLayer.maxAmplitude
              foregroundFluidLayer.frequency = env.options.foregroundFluidLayer.frequency || foregroundFluidLayer.frequency
              foregroundFluidLayer.horizontalSpeed = env.options.foregroundFluidLayer.horizontalSpeed || foregroundFluidLayer.horizontalSpeed
            }

            if (env.options.backgroundFluidLayer) {
              backgroundFluidLayer.fillStyle = env.options.backgroundFluidLayer.fillStyle || backgroundFluidLayer.fillStyle
              backgroundFluidLayer.angularSpeed = env.options.backgroundFluidLayer.angularSpeed || backgroundFluidLayer.angularSpeed
              backgroundFluidLayer.maxAmplitude = env.options.backgroundFluidLayer.maxAmplitude || backgroundFluidLayer.maxAmplitude
              backgroundFluidLayer.frequency = env.options.backgroundFluidLayer.frequency || backgroundFluidLayer.frequency
              backgroundFluidLayer.horizontalSpeed = env.options.backgroundFluidLayer.horizontalSpeed || backgroundFluidLayer.horizontalSpeed
            }
          }


          bubblesLayer.init()
          setupCanvas()
          draw()
        },
        setPercentage(percentage) {

          fillPercentage = clamp(percentage, 0, 100)
        }
      }
    }
  },
  mounted() {
    this.getScreenType()
    window.axios.get('api/exam/performance').then((res) => {
      this.performance = res.data.data
      this.performance.usedRate = Math.floor(this.performance.total * 100 / (this.performance.unused + this.performance.total))
      if (this.performance.score >= 70) {
        this.borderColor = "#319e71"
        this.backColor = "#fdbd68"
        this.moj = 25
      } else if (this.performance.score >= 50) {
        this.borderColor = "#39bf88"
        this.backColor = "#fdbd68"
        this.moj = 15
      } else if (this.performance.score >= 30) {
        this.borderColor = "#fdbd68"
        this.backColor = "#e5834c"
        this.moj = 10
      } else {
        this.borderColor = "#f1803f"
        this.backColor = "#f1803f"
        this.moj = 5
      }
      fm2.init({
        targetContainer: document.getElementById("fluid-meter-2"),
        fillPercentage: this.performance['score'],
        options: {
          fontFamily: "Blinker-SemiBold",
          drawPercentageSign: true,
          drawBubbles: true,
          size: 300,
          borderWidth: 5,
          backgroundColor: this.backColor,
          foregroundColor: this.borderColor,
          foregroundFluidLayer: {
            fillStyle: "#39bf88",
            angularSpeed: 90,
            maxAmplitude: this.moj - (this.moj / 2),
            frequency: 25,
            horizontalSpeed: -200
          },
          backgroundFluidLayer: {
            fillStyle: "#5dd9a7",
            angularSpeed: 100,
            maxAmplitude: this.moj,
            frequency: 23,
            horizontalSpeed: 230
          }
        }
      })
    })
    var fm2 = this.FluidMeter()
    console.log(self.performance['correct'])
    window.onload = function () {
      document.getElementById("submit-percentage-2").onclick = function () {
        fm2.setPercentage(Number(document.getElementById("percentage-2").value))
      }
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.big {
  font-size: 19px;
}

.icon-page-hover:hover {
  color: #39bf88
}

.icon-view-hover:hover {
  color: #f1803f !important;
}
</style>